<template>

  <div>

    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th />
          <th>Start Time</th>
          <th>Elapsed</th>
          <th>New Bytes</th>
          <th>Progressed Bytes</th>
          <th>Plugin</th>
          <th>Status</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="job in orderedJobs"
        >
          <tr
            :key="job.id"
          >
            <td>
              <feather-icon
                :icon="job.showDetails === true ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="cursor-pointer float-left"
                @click="toggleDetails(job)"
              />
              <JobStatusIcon :status="job.status" />
            </td>
            <td>{{ job.startTime | formatDateTimeTz(timezone) }}</td>
            <td>{{ job.elapsed | formatClrTimeSpan }}</td>
            <td>{{ job.newBytesPercentage }}%</td>
            <td>{{ job.progressBytes | prettyBytes }}</td>
            <td>{{ job.plugin }}</td>
            <td>{{ job.statusText }}</td>
            <td>{{ job.typeText }}</td>
          </tr>
          <tr
            v-if="job.showDetails"
            :key="`${job.id}_detail`"
          >
            <td
              colspan="8"
            >
              <div class="row p-2">
                <div class="col">
                  <dl>
                    <dt>Job Id</dt>
                    <dd>{{ job.jobId }}</dd>

                    <dt>Error Code</dt>
                    <dd>{{ job.errorCode }}</dd>

                    <dt>Start</dt>
                    <dd>{{ job.startTime | formatDateTimeTz(timezone) }}</dd>

                    <dt>End</dt>
                    <dd>{{ job.endTime | formatDateTimeTz(timezone) }}</dd>

                    <dt>Scheduled Start</dt>
                    <dd>{{ job.scheduledStart | formatDateTimeTz(timezone) }}</dd>

                    <dt>Scheduled End</dt>
                    <dd>{{ job.scheduledEnd | formatDateTimeTz(timezone) }}</dd>
                  </dl>
                </div>
                <div class="col">
                  <dl>
                    <dt>Elapsed Wait</dt>
                    <dd>{{ job.elapsedWait | formatClrTimeSpan }}</dd>

                    <dt>Elapsed</dt>
                    <dd>{{ job.elapsed | formatClrTimeSpan }}</dd>

                    <dt>Progress Bytes</dt>
                    <dd>{{ job.progressBytes | prettyBytes }}</dd>

                    <dt>New Bytes</dt>
                    <dd>{{ job.newBytesPercentage }}%</dd>
                  </dl>
                </div>
                <div class="col">
                  <dl>
                    <dt>Dataset</dt>
                    <dd>{{ job.dataset }}</dd>

                    <dt>Status</dt>
                    <dd>{{ job.statusText }}</dd>

                    <dt>Type</dt>
                    <dd>{{ job.typeText }}</dd>
                  </dl>
                </div>
                <div class="col">
                  <dl>
                    <dt>Group</dt>
                    <dd>
                      <b-link :to="{ name: 'avamar-detail.group-detail', params: { jobGroup: job.group } }">
                        {{ job.group }}
                      </b-link>
                    </dd>

                    <dt>Container</dt>
                    <dd>{{ job.container }}</dd>

                    <dt>Retention Policy</dt>
                    <dd>{{ job.retentionPolicy }}</dd>

                    <dt>Domain</dt>
                    <dd>{{ job.domain }}</dd>

                    <dt>Plugin</dt>
                    <dd>{{ job.plugin }}</dd>

                    <dt>Wid</dt>
                    <dd>{{ job.wid }}</dd>
                  </dl>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

import { BLink } from 'bootstrap-vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'

export default {
  components: {
    JobStatusIcon,
    BLink,
  },
  props: {
    jobs: {
      type: Array,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
  },
  computed: {
    orderedJobs() {
      return this.jobs.concat().sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
    },
  },
  methods: {
    toggleDetails(job) {
      // eslint-disable-next-line no-param-reassign
      this.$set(job, 'showDetails', !job.showDetails)
    },
  },
}

</script>
