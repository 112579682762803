<template>
  <div>
    <b-card title="Client Details">
      <b-row>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th style="width: 220px">
                  Client
                </th>
                <td>
                  {{ client.clientName }}
                </td>
              </tr>
              <tr>
                <th>
                  OS
                </th>
                <td>
                  {{ client.os }}
                </td>
              </tr>
              <tr>
                <th>
                  Client Release
                </th>
                <td>
                  {{ client.clientRelease }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
            :timezone="asup.timezone"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="onlyErrors"
            switch
          >
            Errors only
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card title="Backup jobs">
      <JobTable
        :jobs="backupJobs"
        :timezone="asup.timezone"
      />
    </b-card>

    <b-card title="Replication jobs">
      <JobTable
        :jobs="replicationJobs"
        :timezone="asup.timezone"
      />
    </b-card>

    <b-card title="Policies">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Policy</th>
            <th>Backup jobs</th>
            <th>Replication jobs</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(group, index) in groups"
            :key="index"
          >
            <th>
              <b-link :to="{ name: 'avamar-detail.group-detail', params: { jobGroup: group.name } }">
                {{ group.name }}
              </b-link>
            </th>
            <td>{{ group.backupJobCount }} <span v-if="group.backupJobFailedCount">({{ group.backupJobFailedCount }} failed)</span></td>
            <td>{{ group.replicationJobCount }} <span v-if="group.replicationJobFailedCount">({{ group.replicationJobFailedCount }} failed)</span></td>
          </tr>
        </tbody>
      </table>
    </b-card>

  </div>
</template>

<script>

import {
  BRow, BCol, BCard, VBPopover, BFormCheckbox, BForm, BFormGroup, BLink,
} from 'bootstrap-vue'
import JobTable from './components/JobTable.vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'

import moment from '@/libs/moment'
import AvamarService from '@/service/avamar.service'

export default {
  components: {
    BRow, BCol, BCard, BFormCheckbox, BForm, BFormGroup, JobTable, DateRangePicker, BLink,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      client: {},
      timeRange: {
        range: 'last7Days',
      },
      onlyErrors: false,
      jobs: [],
    }
  },
  computed: {
    clientName() {
      return this.$route.params.clientName
    },
    filteredJobs() {
      if (this.onlyErrors === true) {
        return this.jobs.filter(x => x.status !== 0)
      }

      return this.jobs
    },
    backupJobs() {
      return this.filteredJobs.filter(x => x.type === 0)
    },
    replicationJobs() {
      return this.filteredJobs.filter(x => x.type === 1)
    },
    groups() {
      return this.jobs.reduce((p, c) => {
        let group = p.find(x => x.name === c.group)
        if (!group) {
          group = {
            name: c.group,
            backupJobCount: 0,
            backupJobFailedCount: 0,
            replicationJobCount: 0,
            replicationJobFailedCount: 0,
          }

          p.push(group)
        }

        if (c.type === 0) {
          // eslint-disable-next-line no-param-reassign
          group.backupJobCount += 1
          if (group.status === 30) {
            group.backupJobFailedCount += 1
          }
        } else if (c.type === 1) {
          // eslint-disable-next-line no-param-reassign
          group.replicationJobCount += 1
          if (group.status === 30) {
            group.replicationJobFailedCount += 1
          }
        }

        return p
      }, [])
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment(this.$route.query.from).toDate(),
        endDate: moment(this.$route.query.to).toDate(),
      }
    }

    if (this.$route.query.onlyErrors === true) {
      this.onlyErrors = true
    }

    // Watch properties after setting timer range from query to avoid loading data too many times
    this.$watch('timeRange', this.loadData)
    this.$watch('onlyErrors', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      AvamarService.getClientAsync(this.asup.id, this.clientName, { disableTenantFilter: true })
        .then(result => {
          this.client = result
        })

      AvamarService.getJobListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        client: this.clientName,
      }, { disableTenantFilter: true })
        .then(result => {
          // automatically show details for failed jobs
          for (let i = 0; i < result.items.length; i += 1) {
            if (result.items[i].status === 30) {
            // eslint-disable-next-line no-param-reassign
              result.items[i].showDetails = true
            }
          }

          this.jobs = result.items
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
